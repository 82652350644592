<template>
    <div class="suggestion-item" @click="$emit('selected-suggestion', suggestion.name )">
        <div class="avatar">
            <img v-if="suggestionType == 'mentions'" src="@assets/img/icons/avatar.svg" alt="User">
            <span v-else>#</span>
        </div>
        {{ suggestion.name }}
    </div>
</template>

<script>
export default {
    name: "SuggestionItem",
    props: {
        suggestionType: {
            type: String,
            default: "mentions"
        },
        suggestion: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.suggestion-item {
    padding: 4px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar {
        background-color: #e4e5e7;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 10px;
        color: #373739;
        font-weight: 600;
    }
}
</style>
